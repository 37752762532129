<template>
  <div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item prop="nickname">
        <a-input v-model="form.nickname" :max-length="128" placeholder="昵称" size="large"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="password">
        <a-input-password v-model="form.password" size="large" placeholder="密码"></a-input-password>
      </a-form-model-item>
      <a-form-model-item prop="confirmed_password">
        <a-input-password v-model="form.confirmed_password" size="large" placeholder="确认密码"></a-input-password>
      </a-form-model-item>
      <a-form-model-item prop="phone_number">
        <a-input v-model="form.phone_number" addon-before="+86" placeholder="手机号码" size="large">
          <a-icon slot="prefix" type="mobile" style="color: rgba(0,0,0,.25);"></a-icon>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="verification_code">
        <a-row :gutter="8">
          <a-col :span="15">
            <a-input v-model="form.verification_code" placeholder="验证码" size="large">
              <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25);"></a-icon>
            </a-input>
          </a-col>
          <a-col :span="9">
            <a-button
              :disabled="disabled"
              size="large"
              style="width: 100%; font-size: 14px;"
              @click="handleSend"
            >
              {{ disabled ? `${time} 秒后重新获取` : '获取验证码' }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" :block="true" :loading="loading" size="large" @click="handleRegister">注册</a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <router-link :to="{ name: 'Login' }">返回</router-link>
    </div>
  </div>
</template>

<script>
import { register, sendRegisterVerificationCode } from '@/api/auth'
import { RSAEncrypt } from '@/utils/encrypt'

export default {
  name: 'Register',
  data () {
    return {
      form: {},
      rules: {
        nickname: [{ message: '请输入昵称', required: true }],
        password: [{ message: '请输入密码', required: true }],
        confirmed_password: [
          { message: '请输入确认密码', required: true },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error('两次密码不一致，请重新输入'))
              } else {
                callback()
              }
            }
          }
        ],
        phone_number: [
          { message: '请输入手机号码', required: true },
          { message: '请输入正确的手机号码', pattern: /^1[3456789]\d{9}$/, trigger: 'blur' }
        ],
        verification_code: [
          { message: '请输入验证码', required: true },
          { message: '请输入 6 位验证码', pattern: /^\d{6}$/, trigger: 'blur' }
        ]
      },
      disabled: false,
      time: 60,
      loading: false
    }
  },
  methods: {
    handleSend () {
      this.$refs.form.validateField('phone_number', error => {
        if (!error) {
          this.disabled = true
          sendRegisterVerificationCode({ phone_number: this.form.phone_number }).then(res => {
            this.$message.success(res.message)
            const interval = window.setInterval(() => {
              if (this.time-- <= 0) {
                this.time = 60
                this.disabled = false
                window.clearInterval(interval)
              }
            }, 1000)
          }).catch(() => { this.disabled = false })
        }
      })
    },
    handleRegister () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          register({
            nickname: this.form.nickname,
            password: RSAEncrypt(this.form.password),
            phone_number: this.form.phone_number,
            verification_code: RSAEncrypt(this.form.verification_code)
          }).then(() => {
            this.$message.success('注册成功')
            this.$router.push({ name: 'Login' })
            this.$refs.form.resetFields()
          }).finally(() => { this.loading = false })
        }
      })
    }
  }
}
</script>
